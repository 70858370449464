import React from "react";
import { Link } from "gatsby";
import Article from "./Article";
import { CompasBreakLine } from "utils";
import { policy } from "staticContent/more/privacy-policy";

const { policyFullText, otherPolicies, pageTitle, policyNote, pageSubtitle } =
  policy;

const showArticles = policyFullText.map(({ title, paragraphs }) => (
  <Article key={title} title={title} paragraphs={paragraphs} />
));

const showOtherPolicies = ({ list }) =>
  list.map((item, index) => (
    <Link to={item.link} key={index}>
      <li className="policy__policies-list-item">{item.name}</li>
    </Link>
  ));

const PrivacyPolicy = () => (
  <>
    <section className="section hero policy">
      <h1 className="hero__banner-text">{pageTitle}</h1>
    </section>
    <section className="section">
      <p className="policy__note">{policyNote}</p>
      {showArticles}
    </section>
    <section className="section">
      <CompasBreakLine />
      <h2 className="section__subtitle policy__subtitle">{pageSubtitle}</h2>
      <p className="policy__note">{otherPolicies.text}</p>
      <ul className="policy__policies-list">
        {showOtherPolicies(otherPolicies)}
      </ul>
    </section>
  </>
);

export default PrivacyPolicy;
