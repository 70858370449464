import React from "react";
import Layout from "utils/Layout";
import PrivacyPolicy from "components/PrivacyPolicy";
import "styles/components/privacyPolicy.scss";
import { policy } from "staticContent/more/privacy-policy";

const IndexPage = () => (
  <Layout subTitle={policy.pageTitle}>
    <PrivacyPolicy />
  </Layout>
);

export default IndexPage;
