const policy = {
  pageTitle: "Polityka prywatności",
  pageSubtitle: "Inne postanowienia",
  policyNote:
    "Poniższa Polityka Prywatności określa zasady, na jakich przetwarzane są Państwa dane osobowe oraz omówienie Państwa podstawowych praw związanych z przetwarzaniem przez nas Państwa danych osobowych.",
  otherPolicies: {
    text: "Poniżej znajdują się nasze pozostałe postanowienia odnoszące się do odrębynch produktów:",
    list: [
      {
        name: "Aplikacja e-historie",
        link: "/polityka-prywatnosci-e-historie",
      },
    ],
  },
  policyFullText: [
    {
      title: "§ 1 Postanowienia ogólne",
      paragraphs: [
        "Administratorem danych jest HyperView sp. z o. o., prowadząca działalność pod adresem: ul. Chorzowska 50, 44-100 Gliwice, o nadanym numerze identyfikacji podatkowej (NIP): 6342834160, o nadanym numerze REGON: 360225711, o nadanym numerze KRS: 0000537819. Ochrona danych odbywa się zgodnie z wymogami powszechnie obowiązujących przepisów prawa, a ich przechowywanie ma miejsce na zabezpieczonych serwerach.",
        "Na potrzeby lepszego odbioru Polityki Prywatności termin „Użytkownik” zastąpiony został określeniem „Ty”, „Administrator” – „My”. Termin „RODO” oznacza Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE",
        "Szanujemy prawo do prywatności i dbamy o bezpieczeństwo danych. W tym celu używany jest m.in. bezpieczny protokół szyfrowania komunikacji (SSL).",
        "Dane osobowe podawane w formularzu kontaktowym są traktowane jako poufne i nie są widoczne dla osób nieuprawnionych.",
        "Każda osoba, której dane dotyczą (jeżeli jesteśmy ich administratorem) ma prawo dostępu do danych, sprostowania, usunięcia lub ograniczenia przetwarzania, prawo sprzeciwu, prawo wniesienia skargi do organu nadzorczego.",
      ],
    },
    {
      title: "§ 2 Administrator Danych",
      paragraphs: [
        "Kontakt z osobą nadzorującą przetwarzanie danych osobowych w organizacji Usługodawcy jest możliwy drogą elektroniczną pod adresem e-mail: it@hyperview.pl.",
        "Zastrzegamy sobie prawo do przetwarzania Twoich danych po rozwiązaniu Umowy lub cofnięciu zgody tylko w zakresie na potrzeby dochodzenia ewentualnych roszczeń przed sądem lub jeżeli przepisy krajowe albo unijne bądź prawa międzynarodowego obligują nas do retencji danych.",
        "Usługodawca ma prawo udostępniać dane osobowe Użytkownika oraz innych jego danych podmiotom upoważnionym na podstawie właściwych przepisów prawa (np. organom ścigania).",
        "Usunięcie danych osobowych może nastąpić na skutek cofnięcia zgody bądź wniesienia prawnie dopuszczalnego sprzeciwu na przetwarzanie danych osobowych.",
        "Usługodawca nie udostępniania danych osobowych innym podmiotom aniżeli upoważnionym na podstawie właściwych przepisów prawa.",
        "Dane osobowe przetwarzają osoby wyłącznie upoważnione przez nas albo przetwarzający, z którymi ściśle współpracujemy.",
      ],
    },
    {
      title: "§ 3 Pliki cookies",
      paragraphs: [
        "Witryna www.hyperview.pl używa cookies. Są to niewielkie pliki tekstowe wysyłane przez serwer www i przechowywane przez oprogramowanie komputera przeglądarki. Kiedy przeglądarka ponownie połączy się ze stroną, witryna rozpoznaje rodzaj urządzenia, z którego łączy się użytkownik. Parametry pozwalają na odczytanie informacji w nich zawartych jedynie serwerowi, który je utworzył. Cookies ułatwiają więc korzystanie z wcześniej odwiedzonych witryn.",
        "Gromadzone informacje dotyczą adresu IP, typu wykorzystywanej przeglądarki, języka, rodzaju systemu operacyjnego, dostawcy usług internetowych, informacji o czasie i dacie, lokalizacji oraz informacji przesyłanych do witryny za pośrednictwem formularza kontaktowego.",
        "Na naszej witrynie wykorzystujemy następujące pliki cookies:",
        [
          "Cookies wewnętrzne – pliki zamieszczane i odczytywane z Urządzenia Użytkownika przes system teleinformatyczny Serwisu",
          "Cookies zewnętrzne – pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez systemy teleinformatyczne Serwisów zewnętrznych",
          "Cookies sesyjne – pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez Serwis podczas jednej sesji danego Urządzenia. Po zakończeniu sesji pliki są usuwane z Urządzenia Użytkownika.",
          "Cookies trwałe – pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez Serwis do momentu ich ręcznego usunięcia. Pliki nie są usuwane automatycznie po zakończeniu sesji Urządzenia chyba że konfiguracja Urządzenia Użytkownika jest ustawiona na tryb usuwanie plików Cookie po zakończeniu sesji Urządzenia.",
        ],
        `Użytkownik w każdej chwili ma możliwość wyłączenia lub przywrócenia opcji gromadzenia cookies poprzez zmianę ustawień w przeglądarce internetowej. Instrukcja zarządzania plikami cookies jest dostępna na stronie: http://www.allaboutcookies.org/manage-cookies
                Dodatkowe dane osobowe, jak adres e-mail, zbierane są jedynie w miejscach, w których użytkownik wypełniając formularz wyraźnie wyraził na to zgodę. Powyższe dane zachowujemy i wykorzystujemy tylko do potrzeb niezbędnych do wykonania danej funkcji.`,
      ],
    },
    {
      title: "§ 4 Cele, do których wykorzystywane są pliki Cookie",
      paragraphs: [
        "Marketing i reklama– Administrator oraz Serwisy zewnętrzne wykorzystują pliki Cookie do celów marketingowych oraz serwowania reklam Użytkowników.",
        "Usługi społecznościowe – Administrator oraz Serwisy zewnętrzne wykorzystują pliki Cookie do wsparcia usług społecznościowych",
      ],
    },
    {
      title: "§ 5 Wymagania Serwisu",
      paragraphs: [
        "Ograniczenie zapisu i dostępu do plików Cookie na Urządzeniu Użytkownika może spowodować nieprawidłowe działanie niektórych funkcji Serwisu.",
        "Administrator nie ponosi żadnej odpowiedzialności za nieprawidłowo działające funkcje Serwisu w przypadku gdy Użytkownik ograniczy w jakikolwiek sposób możliwość zapisywania i odczytu plików Cookie.",
      ],
    },
    {
      title: "§ 6 Zmiany w Polityce Cookie",
      paragraphs: [
        "Administrator zastrzega sobie prawo do dowolnej zmiany niniejszej Polityki Cookie bez konieczności informowania o tym użytkowników.",
        "Wprowadzone zmiany w Polityce Cookie zawsze będą publikowane na tej stronie.",
        "Wprowadzone zmiany wchodzą w życie w dniu publikacji Polityki Cookie.",
      ],
    },
  ],
};

export { policy };
