import React from "react";

const getHelperList = list =>
  list.map((item, index) => (
    <ul className="policy__article-list--unordered" key={index}>
      <li className="policy__article-list-item">{item}</li>
    </ul>
  ));

const Article = ({ title, paragraphs }) => (
  <article className="policy__article">
    <h2 className="policy__article-title">{title}</h2>
    <ol className="policy__article-list--ordered">
      {paragraphs.map((pargarph, index) =>
        typeof pargarph === "string" ? (
          <li className="policy__article-list-item" key={index}>
            {pargarph}
          </li>
        ) : (
          getHelperList(pargarph)
        )
      )}
    </ol>
  </article>
);

export default Article;
